import styled from "@emotion/styled"
import React from "react"
import Img from "gatsby-image"
import { navigate } from "gatsby"

const Grid = styled.div`
  display: grid;
  width: 100%;
  height: 100%;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  ${(props) => props.theme.bp.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const GridItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }
`

const Image = styled(Img)`
  width: 100%;
`

const RelatedProducts = ({ products }) => {
  return (
    <Grid>
      {products &&
        products.map((product) => {
          return (
            <GridItem
              key={product.id}
              onClick={() => navigate(`/${product.fullSlug}`)}
            >
              <Image
                id={product.thumbnail.id}
                fluid={product.thumbnail.fluid}
              />
            </GridItem>
          )
        })}
    </Grid>
  )
}

export default RelatedProducts
