import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import ProductPage from "../components/product/product-page"
import { Box, Flex, Text } from "rebass"
import StoreContext from "../context/store-context"
import RelatedProducts from "../components/product/related-products"
import { relatedProducts } from "../utils/fragments"

const ProductPageTop = styled(Flex)`
  display: flex;

  justify-content: flex-end;
  align-items: flex-end;

  ${(props) => props.theme.bp.desktop} {
    height: 500px;
    padding-right: 35px;
    margin-bottom: 20px;
  }

  padding-left: 15px;
  padding-right: 15px;

  height: 400px;

  div {
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    min-height: 50px;

    padding-left: 20px;
    padding-right: 35px;

    width: 100%;

    ${(props) => props.theme.bp.tablet} {
      font-size: 16px;
    }

    ${(props) => props.theme.bp.mobile} {
      font-size: 14px;
      padding-left: 0px;
      padding-right: 0px;
      text-align: center;
    }

    ${(props) => props.theme.bp.desktop} {
      font-size: 18px;
    }
  }
`

const ProductPageTemplate = ({ pageContext, data, location }) => {
  const { contentfulProductVariant: variant, contentfulProduct: product } = data

  const [showSizeGuide, setShowSizeGuide] = useState(false)

  return (
    <Layout
      isCherishedObjects={location.pathname.includes("cherished-objects")}
    >
      <Helmet title={`${product.title}`} />
      <ProductPageTop>
        <Text sx={{ textTransform: "uppercase" }}>{product.title}</Text>
      </ProductPageTop>
      <ProductPage
        product={product}
        variant={variant}
        setShowSizeGuide={setShowSizeGuide}
        showSizeGuide={showSizeGuide}
      />
    </Layout>
  )
}

export default ProductPageTemplate

export const query = graphql`
  query($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      ...CollectionProduct
    }
  }
`
